table{
    width: 100%;
    text-align: center;
    margin: auto;
}
table >tbody >tr >th {
    color: #ffffff;
    background: #293b97;
    font-weight: 500;
    padding: 8px;
}
table >tbody >tr >td {
    border: 2px solid #eee;
    padding: 8px;
}